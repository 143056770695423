import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Encode.css';

export class Encode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentText: '',
            decodeToggle: this.props.decode,
            breakToggle: true
        };
    }

    handleChange(event) {
        this.convertText(event.target.value);
    }

    convertText(targetText) {
        let text;
        if (this.state.decodeToggle) {
            const decode = require('html-entities').decode;
            text = decode(targetText);
            console.log(text);
            if (this.state.breakToggle) {
                text = text.replace(/(<br \/>|<br>|<br\/>)/g, "\n");
            }
        } else {
            const encode = require('html-entities').encode;
            text = encode(targetText, { level: 'all', mode: 'nonAscii' });
            console.log(text);
            if (this.state.breakToggle) {
                text = text.replace(/ *\r?\n(?!$)/g, "<br />"); // replace breaks (spaces before break will be removed)
                text = text.replace(/<br \/>(?!<br \/>)/g, "<br />\n"); // insert new lines
            }
        }

        this.setState({
            currentText: text
        });
    }

    handleChangeBreakToggle() {
        const textToConvert = document.getElementById('textToConvert').value;

        this.setState({
            breakToggle: !this.state.breakToggle
        }, function () {
            this.convertText(textToConvert);
        }.bind(this));
    }

    render() {
        return (
            <div>
                <div className="btn-group">
                    <button className={`btn ${!this.state.decodeToggle ? "btn btn-primary" : "btn btn-dark"}`} width="50">
                        <NavLink tag={Link} to="/encode">Encode</NavLink>
                    </button>
                    <button className={`btn ${this.state.decodeToggle ? "btn btn-primary" : "btn btn-dark"}`} width="50">
                        <NavLink tag={Link} to="/decode">Decode</NavLink>
                    </button>
                </div>
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="customCheck1" defaultChecked={this.state.breakToggle} onChange={this.handleChangeBreakToggle.bind(this)} />
                    <label className="custom-control-label active" htmlFor="customCheck1">
                        Convert line breaks
                    </label>
                </div>
                <textarea className="textArea" id="textToConvert" onChange={this.handleChange.bind(this)} placeholder="Enter text to convert" />
                <textarea className="textArea" id="convertedText" value={this.state.currentText} placeholder="Result" readOnly />
            </div >
        );
    }
}
