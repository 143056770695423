import React, { Component } from 'react';

export class Pizza extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputNumber: 1,
            inputWeight: 250,
            inputWaterPercentage: 62.5,
            inputSaltPercentage: 4,
            inputYeastPercentage: 0.1
        };
    }

    componentDidMount() {
        this.calculatePizza();
    }

    onChange = (event) => {
        this.setState({ [event.target.id]: event.target.value }, () =>
            this.calculatePizza()
        );
    }

    calculatePizza() {
        const inputWater = this.state.inputWaterPercentage;
        const inputNumber = this.state.inputNumber;
        const inputWeight = this.state.inputWeight;
        const inputSalt = this.state.inputSaltPercentage;
        const inputYeast = this.state.inputYeastPercentage;
        const water = (inputNumber * inputWeight) / (1 + (1 / (inputWater / 100)) + (inputSalt / 100) + (inputYeast / 100));
        const flour = water / (inputWater / 100);
        const salt = inputSalt * (water / 100);
        const yeast = inputYeast * (water / 100);

        this.setState({
            water: water.toFixed(1),
            flour: flour.toFixed(1),
            salt: salt.toFixed(1),
            yeast: yeast.toFixed(2)
        });
    }

    render() {
        return (
            <div>
                <div className="container">
                    <h1>Pizza Napoletana dough calculator</h1>

                    <p>Based on the <a href="https://www.pizzanapoletana.org/public/pdf/disciplinare%202008%20UK.pdf" target="_blank" rel="noopener noreferrer">Verace Pizza Napoletana</a> trademark.</p>

                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label htmlFor="inputNumber">Number of pizzas</label>
                                <input type="number" min="1" className="form-control" onChange={this.onChange}
                                    id="inputNumber" placeholder="Number of pizza's" value={this.state.inputNumber} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputWeight">Desired dough weight in grams</label>
                                <input type="number" min="1" className="form-control" onChange={this.onChange}
                                    id="inputWeight" placeholder="Weight in grams" value={this.state.inputWeight} />
                                <small id="weightHelp" className="form-text text-muted">Between 200 and 300 is advised (default is 250 grams)</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputWater">Percentage of water</label>
                                <input type="number" min="1" max="100" className="form-control" onChange={this.onChange}
                                    id="inputWaterPercentage" placeholder="Water percentage" value={this.state.inputWaterPercentage} step="0.5" />
                                <small id="waterHelp" className="form-text text-muted">Between 50% and 70% is advised (default is 62.5%)</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputSaltPercentage">Percentage of salt</label>
                                <input type="number" min="1" max="100" className="form-control" onChange={this.onChange}
                                    id="inputSaltPercentage" placeholder="Salt percentage" value={this.state.inputSaltPercentage} step="0.5" />
                                <small id="saltHelp" className="form-text text-muted">Default is 4%</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputYeastPercentage">Percentage of yeast</label>
                                <input type="number" min="0.05" max="100" className="form-control" onChange={this.onChange}
                                    id="inputYeastPercentage" placeholder="Yeast percentage" value={this.state.inputYeastPercentage} step="0.05" />
                                <small id="yeastHelp" className="form-text text-muted">Default is 0.1%</small>
                            </div>
                        </div>

                        <div className="col-sm">
                            <ul className="list-group">
                                <li className="list-group-item">{this.state.water} g water</li>
                                <li className="list-group-item">{this.state.flour} g flour</li>
                                <li className="list-group-item">{this.state.salt} g salt</li>
                                <li className="list-group-item">{this.state.yeast} g dry yeast</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
