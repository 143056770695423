import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Encode } from './components/Encode';
import { Base64 } from './components/Base64';
import { Pizza } from './components/Pizza';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/encode' render={(props) => (
                    <Encode {...props} decode={false} />
                )} />
                <Route path='/decode' render={(props) => (
                    <Encode {...props} decode={true} />
                )} />
                <Route exact path='/base64' component={Base64} />
                <Route exact path='/pizza' component={Pizza} />
            </Layout>
        );
    }
}
