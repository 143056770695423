import React, { Component } from 'react';

export class Base64 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentText: ''
        };
    }

    handleConvert(method) {
        try {
            let text = document.getElementById('targetText').value;
            const base64 = require('base-64');
            const utf8 = require('utf8');

            if (method === 'encode') {
                const bytes = utf8.encode(text);
                text = base64.encode(bytes);
            }

            if (method === 'decode') {
                const bytes = base64.decode(text);
                text = utf8.decode(bytes);
            }

            this.setState({
                currentText: text
            });
        } catch (e) {
            this.setState({
                currentText: e
            });
        }
    }

    render() {
        return (
            <div className="text-center">
                <textarea className="textArea" id="targetText" placeholder="Enter text to convert" />
                <div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button type="button" onClick={() => this.handleConvert('encode')} className="btn btn-outline-primary">Encode</button>
                    <button type="button" onClick={() => this.handleConvert('decode')} className="btn btn-outline-primary">Decode</button>
                </div>
                <textarea className="textArea" id="resultText" value={this.state.currentText} placeholder="Result" readOnly />
            </div >
        );
    }
}
