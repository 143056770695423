import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div className="text-center">
                <h1 className="display-4 text-muted">tools.straathof.dev</h1>
                <p>
                    <NavLink tag={Link} to="/encode">Encode special characters</NavLink>
                    <NavLink tag={Link} to="/decode">Decode special characters</NavLink>
                    <NavLink tag={Link} to="/base64">Base64 encoder/decoder</NavLink>
                    <NavLink tag={Link} to="/pizza">Pizza Napoletana dough calculator</NavLink>
                </p>
            </div>
        );
    }
}
